import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '@/store';
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import TableDeliveryState from "../../models/TableDeliveryState";
import DeliveryRow from "@/models/delivery/DeliveryRows";
import DeliveryService from "@/core/services/delivery/DeliveryService";
import DeliveryTableService from "@/core/services/delivery/interfaces/DeliveryTableService";
import Actions from "./Actions";
import { Mutations } from "./Mutations";
import Modules from "@/store/enums/Modules";
import DeliveryFilter from "@/models/delivery/DeliveryFilter";
import { SaveValuesToStore } from "@/core/shared/AssingProps";

@Module({dynamic: true, store, namespaced: true, name: Modules.DeliveryTableModule})
export default class TableDeliveryModule extends VuexModule implements TableDeliveryState {

    records = [] as DeliveryRow[];

    filters = {
        beginAt: ''
        , endAt: ''
    } as DeliveryFilter;

    loading = false;

    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_GET_DELIVERY](table: DeliveryRow[]){
        this.records = table;
    }

    /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     * @param paginator paginador de las notas
     */
    @Action({ commit: Mutations.SET_GET_DELIVERY })
     async [Actions.GET_DELIVERY](filter: DeliveryFilter){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
         const resp = (await service.getTableInfo(filter)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             
                [ 
                    
                
                ] as DeliveryRow[]
             

        console.log(resp)
 
         this.context.commit(Mutations.SET_GET_DELIVERY, resp);

        return resp;
    }
   
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.dispatch(Actions.GET_DELIVERY);
    }
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
 const service =  new DeliveryService() as DeliveryTableService;