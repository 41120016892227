const formatToCalendar = (arrayObj) => {
    let arrayRows = [];
    if(arrayObj){
        arrayObj.forEach(obj => {
            var date = new Date(obj.saleDeliveryPlanAt);
            date.setHours(date.getHours() + 2);


            let objRow = {};
            objRow.id = obj.id;
            objRow.title = `Folio: ${obj.invoince}, cliente: ${obj.customerName}`;
            objRow.start = obj.saleDeliveryPlanAt;
            objRow.end = date.toISOString();
            
            arrayRows.push(objRow);
        });
    }
    
    return arrayRows;
}

export { formatToCalendar }